<template>
    <div class="secret">
        <div style="height: 100%" v-show="secretStatus === 1">
            <el-form ref="ruleForm" class="demo-ruleForm" v-if="secretList.length > 0">
                <div v-for="(ele, index) in SecretList">
                    <el-form-item>
                        <span>密保问题{{ index + 1 }}</span>
                        <el-select v-model="ele.problem" disabled placeholder="请选择密保问题">
                            <el-option v-for="item in optionList" :key="item.id" :label="item.label" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <span>密保答案</span>
                        <br>

                        <el-input v-if="ele.answer" value="******" readonly></el-input>
                        <el-input v-else value="未设置"></el-input>
                    </el-form-item>
                </div>

            </el-form>
            <el-form ref="ruleForm" class="demo-ruleForm" v-else>
                <div v-for="(ele, index) in SecretList">
                    <el-form-item>
                        <span>密保问题{{ index + 1 }}</span>
                        <el-select v-model="ele.problem" disabled placeholder="请选择密保问题">
                            <el-option v-for="item in optionList" :key="item.id" :label="item.label" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <span>密保答案</span>
                        <br>
                        <el-input value="暂未设置" readonly></el-input>
                    </el-form-item>
                </div>

            </el-form>
            <div class="btn">
                <button @click="changeSecret">修改</button>
            </div>
        </div>
        <div style="height: 100%"  v-show="secretStatus === 2">
            <el-form class="demo-ruleForm">
                <el-form-item>
                    <span>账户验证</span>
                    <br>
                    <el-input v-model="userPwd" placeholder="请输入账号登录密码" type="password" show-password></el-input>
                </el-form-item>
            </el-form>
            <div class="btn">
                <button @click="submit">确定</button>
            </div>
        </div>
        <div style="height: 100%"  v-show="secretStatus === 3">
            <el-form ref="ruleForm" class="demo-ruleForm">
                <div v-for="(ele, index) in SecretList">
                    <el-form-item>
                        <span>密保问题{{ index + 1 }}</span>
                        <el-select v-model="ele.problem" placeholder="请选择密保问题">
                            <el-option v-for="item in optionList" :key="item.id" :label="item.label" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <span>密保答案</span>
                        <br>
                        <el-input v-model="ele.answer"></el-input>
                    </el-form-item>
                </div>

            </el-form>
            <div class="btn">
                <button @click="submitForm">确认</button>
            </div>
        </div>
    </div>
</template>

<script>
import { getSecret, ClientPwd, updateSecret } from '@/api/personal'
export default {
    data() {
        return {
            secretStatus: 1,
            userPwd: null,
            secretList: [],
            // updateList: [{id: }],
            rules: {
                name: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                    { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ]
            },
            SecretList: [{ problem: null, answer: null }, { problem: null, answer: null }, { problem: null, answer: null }],
            optionList: [{ id: 1, label: '您父亲的名字？' }, { id: 2, label: '您母亲的名字？' }, { id: 3, label: '您汽车的品牌？' }, { id: 4, label: '您手机的品牌？' }, { id: 5, label: '您喜欢的颜色？' }, { id: 6, label: '您最喜欢吃的水果？' }, { id: 7, label: '您的家乡在哪里？' }, { id: 8, label: '您最好的伙伴是谁？' }]
        };
    },
    methods: {
        // 提交修改
        async submitForm() {
            console.log(this.SecretList);

            let status = 0;
            let num = 0;
            this.SecretList.forEach(async (item) => {
                if (item.problem !== null && item.answer === null || item.answer === '') {
                    status = 1;
                } else if (item.problem === null && item.answer !== null) {
                    num = 1;
                }
            })
            // 选择问题也选择了答案
            if (status === 0) {
                // 写了答案未选择问题
                if (num === 1) {
                    this.$message({
                        showClose: true,
                        message: '请选择问题！'
                    });
                } else {
                    const res = await updateSecret(JSON.stringify(this.SecretList));
                    console.log(res);
                    if (res.code === 200) {
                        this.$message({
                            showClose: true,
                            message: '修改成功！',
                            type: 'success'
                        });
                        this.secretStatus = 1;
                        this.getUserSecret();
                    } else {
                        this.$message({
                            showClose: true,
                            message: '修改失败！',
                            type: 'error'
                        });
                    }
                }


            } else {
                this.$message({
                    showClose: true,
                    message: '请输入问题答案！'
                });
            }

            // this.$refs[formName].validate((valid) => {
            //     if (valid) {
            //         alert('submit!');
            //     } else {
            //         console.log('error submit!!');
            //         return false;
            //     }
            // });
        },
        // 获取用户设置的密保问题
        async getUserSecret() {
            const res = await getSecret({ id: Number(JSON.parse(localStorage.getItem('userInfo')).id) });
            console.log(res);
            if (res.code === 200) {
                this.secretList = res.data;
                // this.SecretList = res.data;
                if (res.data.length > 0) {
                    this.SecretList = res.data;
                    // this.secretList = res.data;
                    // this.SecretList = res.data;
                    // this.SecretList[0] = res.data[0];
                    // this.SecretList[1] = res.data[1];
                    // this.SecretList[2] = res.data[2];
                }
            }
        },
        // 点击修改按钮
        changeSecret() {
            this.secretStatus = 2;
        },
        // 验证当前账户
        async submit() {
            if (this.userPwd === '' || this.userPwd === null) {
                this.$message({
                    showClose: true,
                    message: '请输入登录密码！'
                });
            } else {
                const res = await ClientPwd({ id: Number(JSON.parse(localStorage.getItem('userInfo')).id), password: this.userPwd });
                console.log(res);
                if (res.code === 200) {
                    this.userPwd = null;
                    this.secretStatus = 3;
                } else {
                    this.$message({
                        showClose: true,
                        message: '验证失败！' + res.msg,
                        type: 'error'
                    });
                }
            }

            // this.telStatus = 2;
        },
    },
    created() {
        this.getUserSecret();
        this.SecretList.forEach(item => {
            item.userId = Number(JSON.parse(localStorage.getItem('userInfo')).id)
        });
    }
}
</script>

<style lang="scss" scoped>
.secret {
    // padding: 40px 0px;
    padding-top: 40px;
    width: 80%;
    margin: auto;
    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
    // box-shadow: 0px 2px 4px 0px rgba(229,0,74,0.3);
    .el-form {
        // overflow-y: scroll;
        // height: calc(100% - 50px);
        span {
            font-size: 16px;
        }

        .el-select,
        .el-date-picker,
        .el-input {
            width: 100%;
            // height: 40px !important;
            // line-height: 40px !important;

        }
    }

    .btn {
        margin-top: 40px;

        button {
            width: 100%;
            height: 40px;
            background: var(--custom-color);
            border-radius: 4px;
            border: none;
            color: white;
            font-size: 14px;
        }
    }
}
</style>